
import AnimatedSea from "@/components/AnimatedSea.vue";
import PalmTree from "@/components/PalmTree.vue";
import { store } from "@/store/index";
import { defineComponent } from "vue";
import router from "./router";

export default defineComponent({
  name: "App",
  components: {
    AnimatedSea,
    PalmTree,
  },
  setup() {
    const { settingsStore } = store;

    function goToSettings() {
      router.push("/settings");
    }

    return { settingsStore, goToSettings };
  },
});
