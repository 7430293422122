
import PlayerCard from "@/components/PlayerCard.vue";
import { Player } from "@/composables/Player";
import { PropType } from "vue";
import { store } from "@/store/index";

const podiumColors: string[] = [
  "border-gold",
  "border-silver",
  "border-bronze",
  "border-light",
];

export default {
  name: "Ranking",
  props: {
    players: {
      type: Array as PropType<Player[]>,
      default(): Array<Player> {
        return [];
      },
    },
  },
  async setup() {
    const { playerStore } = store;
    await playerStore.fetchPlayerList();
    return { playerStore };
  },
  methods: {
    getBorderColor(index: number): string {
      if (index > 2) {
        return podiumColors[3];
      }
      return podiumColors[index];
    },
  },
  components: {
    PlayerCard,
  },
};
