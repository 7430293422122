import { AxiosService } from "@/axios/axios.service";
import { Activity } from "@/composables/activity";
import { Ref, ref } from "vue";

export class ActivityStore {
  private activityList: Ref<Activity[]> = ref([]);

  private cache = {};

  getActivityList = (): Ref<Activity[]> => {
    return this.activityList;
  };

  fetchActivityList = async (): Promise<void> => {
    const response = await AxiosService.get("/activity/all", this.cache);
    this.activityList.value = response;
  };

  lazyFetcActivityList = async (): Promise<void> => {
    if (this.activityList.value.length > 0) {
      return;
    }
    await this.fetchActivityList();
  };
}
