import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e8b4f310"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "rank_col",
  class: "col_"
}
const _hoisted_2 = { id: "rank" }
const _hoisted_3 = {
  id: "img_col",
  class: "col_"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  id: "name_col",
  class: "col_"
}
const _hoisted_6 = { id: "name" }
const _hoisted_7 = {
  id: "points_col",
  class: "col_"
}
const _hoisted_8 = { id: "points" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "playerFrame",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickhandler && _ctx.onClickhandler(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.rank) + ".", 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.player.icone)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.getImgUrl(_ctx.player.icone),
            alt: "icone joueur",
            id: "icone"
          }, null, 8, _hoisted_4))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.player.name), 1)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("h2", _hoisted_8, _toDisplayString(_ctx.player.points) + " pts", 1)
    ])
  ]))
}