
import RankingCard from "@/components/RankingCard.vue";
import { Activity } from "@/composables/activity";

const podiumColors: string[] = [
  "border-gold",
  "border-silver",
  "border-bronze",
  "border-light",
];
export default {
  name: "VersusActivity",

  props: {
    activity: { type: Activity },
  },
  components: {
    RankingCard,
  },

  methods: {
    getBorderColor(index: number): string {
      if (index > 2) {
        return podiumColors[3];
      }
      return podiumColors[index];
    },
  },
};
