import { AxiosService } from "@/axios/axios.service";
import { Match } from "@/composables/match";
import { Ref, ref } from "vue";
export class MatchStore {
  private matchList: Ref<Match[]> = ref([]);

  getMatchList = (): Ref<Match[]> => {
    return this.matchList;
  };

  fetchMatchList = async (): Promise<void> => {
    const response = await AxiosService.get("/match/all");

    this.matchList.value = response;
  };

  fetchPlayerMatchList = async (userName: string): Promise<void> => {
    const response = await AxiosService.get("/match/all", {
      containsPlayer: userName,
    });

    this.matchList.value = response;
  };

  sendNewMatch = async (matchProps: Partial<Match>): Promise<number | void> => {
    const resp = await AxiosService.post("/match", matchProps);
    return resp;
  };
}
