
import { Player } from "@/composables/Player";
import router from "@/router";
import { defineComponent } from "vue";
import { getImgUrl } from "@/composables/utils";

export default defineComponent({
  name: "PlayerCard",
  props: {
    player: { type: Player, default: {} },
    rank: { type: Number },
  },
  setup(props) {
    function onClickhandler() {
      router.push({
        name: `Joueur`,
        params: { playerName: props.player.name },
      });
    }

    return { onClickhandler, getImgUrl };
  },
});
