import { Player } from "./Player";
import { Match, MatchTeam } from "./match";

//constantes
const DINERPP_OUT_OF = "/20";
const OVERCOOKED_INTRO = "score de ";
const SAUT_DE_LIGNE = "<br>";
const mois = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

export const buildPlayerBodyCard = (match: Match, player: Player): string => {
  switch (match.activityType) {
    case "versus":
      return buildVersusBody(match, player);

    case "singleTeamPerf":
      return buildSingleTeamPerfBody(match, player);

    case "ranking":
      return buildRankingBody(match, player);
  }
  return "";
};

export const buildPlayerHeaderCard = (match: Match, player: Player): string => {
  switch (match.activityType) {
    case "versus":
      return buildVersusHeader(match, player);

    case "singleTeamPerf":
      return buildSingleTeamPerfHeader(match, player);

    case "ranking":
      return buildRankingHeader(match, player);
  }
  return "";
};

export const buildPlayerFooterCard = (match: Match, player: Player): string => {
  const team = getPlayerTeam(match, player);

  return `+ ${team?.limouscore} points !`;
};

export const buildPlayerSubtitleCard = (match: Match): string => {
  const date = new Date(+match.date);
  const jour = date.getDate();
  const moisActuel = date.getMonth();
  const heure = date.getHours();
  const minute = date.getMinutes();

  return jour + " " + mois[moisActuel] + " " + heure + "h" + minute;
};

const getPlayerTeam = (match: Match, player: Player): MatchTeam | undefined => {
  const team = match.teams.find((team) => {
    return team.players.some((element) => {
      return element.name === player.name;
    });
  });

  return team;
};

const getListedPlayersFromATeam = (team: MatchTeam): string => {
  let returnStatement = "";
  team.players.forEach((player) => (returnStatement += `${player.name} / `));

  if (returnStatement.length > 2)
    returnStatement = returnStatement.substring(0, returnStatement.length - 3);
  return returnStatement;
};

//orderDesc == false => ordre croissant;
//orderDesc == true => ordre décroissant;
const sortTeamsByRank = (match: Match, orderDesc: boolean): MatchTeam[] => {
  return match.teams.sort((teamA, teamB) => {
    const rankA = teamA.rank;
    const rankB = teamB.rank;
    if (rankA < rankB) {
      return orderDesc ? 1 : -1;
    }
    if (rankA > rankB) {
      return orderDesc ? -1 : 1;
    }
    return 0;
  });
};

const buildVersusBody = (match: Match, player: Player): string => {
  const isWinner = getPlayerTeam(match, player)?.rank == 1 ? true : false;
  const teams = sortTeamsByRank(match, !isWinner); //negation car true == ordre Desc

  let returnStatement = "";

  returnStatement += `${getListedPlayersFromATeam(teams[0])}`;
  returnStatement += `${SAUT_DE_LIGNE} ${teams[0].activityScore} - ${teams[1].activityScore} `;
  returnStatement += `${SAUT_DE_LIGNE} ${getListedPlayersFromATeam(teams[1])}`;

  return returnStatement;
};

const buildSingleTeamPerfBody = (match: Match, player: Player): string => {
  let returnStatement = "";

  //normalement c'est match.teams[0] car il n'y a qu'une team mais on sait jamais
  const team = getPlayerTeam(match, player);

  if (team) returnStatement += `${getListedPlayersFromATeam(team)}`;

  return returnStatement;
};

const buildRankingBody = (match: Match, player: Player): string => {
  //exactement le même body
  return buildSingleTeamPerfBody(match, player);
};

const buildSingleTeamPerfHeader = (match: Match, player: Player): string => {
  let returnStatement = "";
  //normalement c'est match.teams[0] car il n'y a qu'une team mais on sait jamais
  const team = getPlayerTeam(match, player);

  switch (match.activity) {
    case "Diner Presque Parfait":
      returnStatement = `${team?.activityScore}${DINERPP_OUT_OF} `;
      break;
    case "overcooked":
      returnStatement = `${OVERCOOKED_INTRO} ${team?.activityScore} `;
      break;
  }
  returnStatement += ` ${match.activity} !`;
  return returnStatement;
};

const buildRankingHeader = (match: Match, player: Player): string => {
  let returnStatement = "";
  //normalement c'est match.teams[0] car il n'y a qu'une team mais on sait jamais
  const team = getPlayerTeam(match, player);

  returnStatement += `${team?.activityScore}`;
  returnStatement += team?.activityScore === 1 ? "er " : "e ";
  returnStatement += `${match.activity} !`;
  return returnStatement;
};

const buildVersusHeader = (match: Match, player: Player): string => {
  let returnStatement = "";
  const team = getPlayerTeam(match, player);

  if (team?.rank == 1) returnStatement += "Victoire";
  else returnStatement += "Défaite";

  returnStatement += ` ${match.activity} !`;
  return returnStatement;
};
