
import { Match } from "@/composables/match";
import { defineComponent } from "vue";
import {
  buildHistoryViewHeaderCard,
  buildHistoryViewBodyCard,
  buildHistoryViewSubtitleCard,
} from "@/composables/historyViewCardHelper";
import { Player } from "@/composables/Player";

export default defineComponent({
  name: "PlayerHistoryalCard",
  props: {
    match: { type: Match, default: {} },
    player: { type: Player, default: {} },
  },
  setup(props) {
    const headerValue = buildHistoryViewHeaderCard(props.match);
    const subtitleValue = buildHistoryViewSubtitleCard(props.match);
    const bodyValue = buildHistoryViewBodyCard(props.match);

    return { headerValue, bodyValue, subtitleValue };
  },
});
