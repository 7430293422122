export class Player {
  name: string;
  icone: string;
  points: number;
  rank: number;

  constructor(name: string, icone: string, points = 0) {
    this.name = name;
    this.icone = icone;
    this.points = points;
    this.rank = 0;
  }
}
