import { Ref, ref } from "vue";

const storagSwaggMode =
  localStorage.getItem("swaggmode") === "true" ? true : false;
export class SettingsStore {
  private swaggMode: Ref<boolean> = ref(storagSwaggMode);

  getSwaggMode = (): Ref<boolean> => {
    return this.swaggMode;
  };

  toggleSwaggMode = () => {
    this.swaggMode.value = this.swaggMode.value ? false : true;
    localStorage.setItem("swaggmode", String(this.swaggMode.value));
  };
}
