import { Player } from "./Player";

export class Match {
  activity: string;
  numberOfTeam: number;
  activityType: string;
  date: number;
  teams: MatchTeam[];

  constructor(
    activity: string,
    numberOfTeam: number,
    activityType: string,
    date: number,
    teams: MatchTeam[]
  ) {
    this.activity = activity;
    this.numberOfTeam = numberOfTeam;
    this.activityType = activityType;
    this.date = date;
    this.teams = teams;
  }
}

export class MatchTeam {
  name: string;
  players: Player[];
  activityScore: number;
  rank: number;
  limouscore: number;

  constructor(
    name: string,
    players: Player[],
    activityScore: number,
    rank: number,
    limouscore: number
  ) {
    this.name = name;
    this.players = players;
    this.activityScore = activityScore;
    this.rank = rank;
    this.limouscore = limouscore;
  }
}

/*
  "activity": "petanque",
  "numberOfTeam": "2",
  "activityType": "versus",
  "date": "2023-07-11 11:11:11",
  "teams": [
    {
      "name": "petanque1",
      "players": [
        {
          "name": "loic"
        },
        {
          "name": "emile"
        }
      ],
      "activityScore": "13",
      "rank": "1",
      "limouscore": "3"
    },
    {
      "name": "petanque2",
      "players": [
        {
          "name": "samy"
        },
        {
          "name": "louis"
        }
      ],
      "activityScore": "7",
      "rank": "2",
      "limouscore": "1"
    }
  ]
*/
