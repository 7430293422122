
import { Match } from "@/composables/match";
import { defineComponent } from "vue";
import {
  buildPlayerHeaderCard,
  buildPlayerBodyCard,
  buildPlayerFooterCard,
  buildPlayerSubtitleCard,
} from "@/composables/playerHistoryCardHelper";
import { Player } from "@/composables/Player";

export default defineComponent({
  name: "PlayerHistoryalCard",
  props: {
    match: { type: Match, default: {} },
    player: { type: Player, default: {} },
  },
  setup(props) {
    const headerValue = buildPlayerHeaderCard(props.match, props.player);
    const subtitleValue = buildPlayerSubtitleCard(props.match);
    const bodyValue = buildPlayerBodyCard(props.match, props.player);
    const footerValue = buildPlayerFooterCard(props.match, props.player);

    return { headerValue, bodyValue, footerValue, subtitleValue };
  },
});
