
import { computed, defineComponent } from "vue";
import router from "@/router";
import { useRoute } from "vue-router";
import { store } from "@/store/index";
import { SettingsStore } from "@/store/settings";

export default defineComponent({
  name: "Player",
  async setup() {
    const route = useRoute();
    const { settingsStore } = store;

    function backToClassement() {
      router.go(-1);
    }

    function toggleSwaggMode() {
      settingsStore.getSwaggMode().value = !settingsStore.getSwaggMode().value;
    }
    const computedSwaggMode = settingsStore.getSwaggMode();

    return {
      backToClassement,
      computedSwaggMode,
      settingsStore,
      toggleSwaggMode,
    };
  },
});
