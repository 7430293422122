export class Activity {
  name: string;
  icone: string;
  ranking: Record<string, unknown>[];
  description: string;
  type: string;
  numberOfTeams: number;

  constructor(
    name: string,
    icone: string,
    ranking: Record<string, unknown>[],
    description: string,
    type: string,
    numberOfTeams = 0
  ) {
    this.name = name;
    this.icone = icone;
    this.ranking = ranking;
    this.description = description;
    this.type = type;
    this.numberOfTeams = numberOfTeams;
  }
}
