
import HistoryViewCard from "@/components/HistoryViewCard.vue";
import { store } from "@/store/index";
import { useRoute } from "vue-router";

export default {
  name: "History",
  async setup() {
    const route = useRoute();
    const { matchStore, playerStore } = store;

    await matchStore.fetchMatchList();

    return { matchStore };
  },

  components: {
    HistoryViewCard,
  },
};
