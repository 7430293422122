import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Ranking from "../views/Ranking.vue";
import Player from "../views/Player.vue";
import History from "../views/History.vue";
import Settings from "../views/Settings.vue";
import Activities from "../views/Activities.vue";
import SaveMatch from "../views/SaveMatch.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Classement",
    component: Ranking,
  },
  {
    path: "/player/:playerName",
    name: "Joueur",
    component: Player,
  },
  {
    path: "/activities",
    name: "Activités",
    component: Activities,
  },
  {
    path: "/history",
    name: "Historique",
    component: History,
  },
  {
    path: "/settings",
    name: "Réglages",
    component: Settings,
  },
  {
    path: "/save-match",
    name: "",
    component: SaveMatch,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
