
import ActivityCard from "@/components/ActivityCard.vue";

import { store } from "@/store/index";

export default {
  name: "Events",
  components: {
    ActivityCard,
  },
  async setup() {
    const { activityStore } = store;
    await activityStore.fetchActivityList();

    return { activityStore };
  },
};
