
import { Activity } from "@/composables/activity";
import { Ref, ref, watch } from "vue";
import {
  initReturnObject,
  togglePlayer,
  teamSelectors,
  togglePlayerList,
  buttonSelected,
  validateMatchRegistration,
} from "@/composables/saveMatchHelper";
import { store } from "@/store/index";
import { Match } from "@/composables/match";
import router from "@/router";
import { getImgUrl } from "@/composables/utils";

export default {
  name: "MatchSaver",
  props: {
    selectedActivity: { type: Activity },
  },
  setup(props: any) {
    const { playerStore, matchStore } = store;
    const disabledButton: Ref<boolean> = ref(true);
    playerStore.lazyFetchPlayList();
    const returnObject: Ref<Partial<Match>> = ref(
      initReturnObject(props.selectedActivity)
    );

    const placeholder: Ref<string> = ref("");
    if (props.selectedActivity.type === "ranking") {
      placeholder.value = "position";
    } else {
      placeholder.value = "score";
    }

    watch(returnObject.value, (current) => {
      disabledButton.value = !validateMatchRegistration(current);
    });

    const pushMatch = async (
      returnObject: Record<string, any>
    ): Promise<void> => {
      document.getElementById("sea")!.style.bottom = "65vh";
      document.getElementById("wave-01")!.style.backgroundColor =
        "rgba(0, 190, 255, 0.8)";
      const resp = await matchStore.sendNewMatch(returnObject);
      if (resp === 201) {
        await new Promise((resolve) =>
          setTimeout(() => {
            router.push("/");
            resolve("");
          }, 2500)
        );
      } else {
        console.error("erreur reseau");
      }
      await new Promise((resolve) =>
        setTimeout(() => {
          document.getElementById("sea")!.style.bottom = "-10vh";
          resolve("");
        }, 500)
      );
      await new Promise((resolve) =>
        setTimeout(() => {
          document.getElementById("wave-01")!.style.backgroundColor =
            "rgba(0, 190, 255, 0.4)";
          resolve("");
        }, 2500)
      );
    };

    return {
      returnObject,
      playerStore,
      togglePlayer,
      pushMatch,
      teamSelectors,
      togglePlayerList,
      buttonSelected,
      placeholder,
      disabledButton,
      getImgUrl,
    };
  },
};
