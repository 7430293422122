
import PlayerHistoryCard from "@/components/PlayerHistoryCard.vue";
import router from "@/router";
import { store } from "@/store/index";
import { useRoute } from "vue-router";

export default {
  name: "Player",
  async setup() {
    const route = useRoute();
    const { matchStore, playerStore } = store;
    const playerName = String(route.params.playerName);

    await playerStore.lazyFetchPlayList();
    const player = playerStore.getOnePlayer(playerName);

    await matchStore.fetchPlayerMatchList(playerName);

    function backToClassement() {
      router.push("/");
    }

    return { matchStore, backToClassement, player };
  },

  components: {
    PlayerHistoryCard,
  },
};
