import { AxiosService } from "@/axios/axios.service";
import { Activity } from "./activity";
import { Ref, ref } from "vue";
import { store } from "@/store/index";
import { Player } from "./Player";

export const initReturnObject = (activity: Activity | undefined) => {
  if (activity !== undefined) {
    const returnObject = {
      activityName: activity.name,
      teams: initTeams(activity),
    };
    return returnObject;
  }
  return {};
};

const initTeams = (activity: Activity) => {
  const teams = [];
  teamSelectors.value = [];
  buttonSelected.value = [];
  for (let i = 0; i < activity.numberOfTeams; i++) {
    teamSelectors.value.push(false);
    buttonSelected.value.push({ ...playerNamesMap });
    teams.push({ players: [], activityScore: null });
  }
  return teams;
};

const init: Record<string, boolean> = {};

const playerNamesMap = store.playerStore
  .getPlayerList()
  .value.reduce((obj, player) => {
    obj[player.name] = false;
    return obj;
  }, init);

export const togglePlayer = (
  playerList: Partial<Player>[],
  playerName: string,
  row: number
): void => {
  buttonSelected.value[row][playerName] =
    !buttonSelected.value[row][playerName];
  const nameList: string[] = playerList.map((player) => player.name || "");
  const playerIndex = nameList.indexOf(playerName);
  if (playerIndex >= 0) {
    playerList.splice(playerIndex, 1);
  } else {
    playerList.push({ name: playerName });
  }
};

export const teamSelectors: Ref<boolean[]> = ref([]);

export const buttonSelected: Ref<Record<string, boolean>[]> = ref([]);

export const togglePlayerList = (index: number): void => {
  teamSelectors.value[index] = !teamSelectors.value[index];
};

export const validateMatchRegistration = (match: any) => {
  let validation = true;
  if (!match?.teams?.length) return false;
  for (const team of match?.teams) {
    validation = validation && team?.players?.length && team?.activityScore;
  }
  return validation;
};
