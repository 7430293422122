const getImgUrl = (image: string): string => {
  let img = "";
  try {
    img = require("../assets/" + image);
  } catch (e) {
    img = require("../assets/icone.png");
  }
  return img;
};

const noSpace = (str: string): string => {
  return str.replaceAll(" ", "_");
};

export { getImgUrl, noSpace };
