import { reactive } from "vue";
import { PlayerStore } from "./player";
import { ActivityStore } from "./activity";
import { MatchStore } from "./match";
import { SettingsStore } from "./settings";

export const store = reactive({
  playerStore: new PlayerStore(),
  activityStore: new ActivityStore(),
  matchStore: new MatchStore(),
  settingsStore: new SettingsStore(),
});
