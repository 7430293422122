
import { defineComponent } from "vue";

export default defineComponent({
  name: "PlayerCard",
  props: {
    ranking: {
      type: Object,
      default: () => {
        return {};
      },
    },
    rank: { type: Number },
  },
});
