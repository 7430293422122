
import { Ref, ref } from "vue";
import { getImgUrl, noSpace } from "@/composables/utils";
import { Activity } from "@/composables/activity";
import RankedActivity from "@/components/RankedActivity.vue";
import VersusActivity from "./VersusActivity.vue";

export default {
  name: "EventCard",
  components: {
    RankedActivity,
    VersusActivity,
  },
  props: {
    activity: {
      type: Activity,
      default: new Activity("Erreur", "icone.png", [], "", ""),
    },
  },
  setup(props: any) {
    let isExtended: Ref<boolean> = ref(false);
    const botDiv = ref(null);

    const iconePath = (icone: string): string => {
      return getImgUrl(icone);
    };

    const idBottom = `bottom_${noSpace(props.activity.name)}`;
    return { isExtended, iconePath, idBottom, botDiv };
  },
};
