import { Match, MatchTeam } from "./match";

//constantes
const DINERPP_OUT_OF = "/20";
const OVERCOOKED_INTRO = "score de ";
const SAUT_DE_LIGNE = "<br>";
const mois = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

export const buildHistoryViewBodyCard = (match: Match): string => {
  switch (match.activityType) {
    case "versus":
      return buildVersusBody(match);

    case "singleTeamPerf":
      return buildSingleTeamPerfBody(match);

    case "ranking":
      return buildRankingBody(match);
  }
  return "";
};

export const buildHistoryViewHeaderCard = (match: Match): string => {
  return match.activity;
};

export const buildHistoryViewSubtitleCard = (match: Match): string => {
  const date = new Date(+match.date);
  const jour = date.getDate();
  const moisActuel = date.getMonth();
  const heure = date.getHours();
  const minute = date.getMinutes();

  return jour + " " + mois[moisActuel] + " " + heure + "h" + minute;
};

const getListedPlayersFromATeam = (team: MatchTeam): string => {
  let returnStatement = "";
  team.players.forEach((player) => (returnStatement += `${player.name} / `));

  if (returnStatement.length > 2)
    returnStatement = returnStatement.substring(0, returnStatement.length - 3);
  return returnStatement;
};

//orderDesc == false => ordre croissant;
//orderDesc == true => ordre décroissant;
const sortTeamsByRank = (match: Match, orderDesc: boolean): MatchTeam[] => {
  return match.teams.sort((teamA, teamB) => {
    const rankA = teamA.rank;
    const rankB = teamB.rank;
    if (rankA < rankB) {
      return orderDesc ? 1 : -1;
    }
    if (rankA > rankB) {
      return orderDesc ? -1 : 1;
    }
    return 0;
  });
};

const buildVersusBody = (match: Match): string => {
  const teams = sortTeamsByRank(match, false);

  let returnStatement = "";

  returnStatement += `${getListedPlayersFromATeam(teams[0])} + ${
    teams[0].limouscore
  } points.`;
  returnStatement += `${SAUT_DE_LIGNE} ${teams[0].activityScore} - ${teams[1].activityScore} `;
  returnStatement += `${SAUT_DE_LIGNE} ${getListedPlayersFromATeam(
    teams[1]
  )} + ${teams[1].limouscore} points.`;

  return returnStatement;
};

const buildSingleTeamPerfBody = (match: Match): string => {
  const returnStatement = `${getListedPlayersFromATeam(match.teams[0])} + ${
    match.teams[0].limouscore
  } points.`;

  return returnStatement;
};

const buildRankingBody = (match: Match): string => {
  let returnStatement = "";

  const teams = sortTeamsByRank(match, false);

  for (let i = 0; i < teams.length; i++) {
    returnStatement += `#${teams[i].activityScore} `;
    returnStatement += `${getListedPlayersFromATeam(teams[i])} `;
    returnStatement += `+ ${teams[i].limouscore} points.`;
    if (i != teams.length) returnStatement += `${SAUT_DE_LIGNE}`;
  }

  return returnStatement;
};
