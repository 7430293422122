import { AxiosService } from "@/axios/axios.service";
import { Player } from "@/composables/Player";
import { Ref, ref } from "vue";
export class PlayerStore {
  private playerList: Ref<Player[]> = ref([]);

  private cache = {};

  getPlayerList = (): Ref<Player[]> => {
    return this.playerList;
  };

  getOnePlayer = (playerName: string): Player => {
    return this.playerList.value.filter(
      (player) => player.name === playerName
    )[0];
  };

  fetchPlayerList = async (): Promise<void> => {
    const response = await AxiosService.get("/player/all");
    this.playerList.value = response;
  };

  lazyFetchPlayList = async (): Promise<void> => {
    if (this.playerList.value.length > 0) {
      return;
    } else {
      await this.fetchPlayerList();
    }
  };
}
