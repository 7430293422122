
import { store } from "@/store/index";
import { getImgUrl } from "@/composables/utils";
import { Activity } from "@/composables/activity";
import { Ref, ref } from "vue";
import router from "@/router";
import MatchSaver from "@/components/MatchSaver.vue";

export default {
  name: "SaveMatch",
  components: {
    MatchSaver,
  },
  setup() {
    const { playerStore, activityStore } = store;

    activityStore.lazyFetcActivityList();

    const choseActivity = (activity: Activity) => {
      selectedActivity.value = activity;
    };

    const iconePath = (icone: string): string => {
      return getImgUrl(icone);
    };
    let selectedActivity: Ref<Activity | undefined> = ref(undefined);

    function backToActivities() {
      router.go(-1);
    }
    return {
      playerStore,
      activityStore,
      choseActivity,
      iconePath,
      selectedActivity,
      backToActivities,
    };
  },
};
