import axios from "axios";
import { config } from "dotenv";

config();

const headers = { "X-API-KEY": process.env.VUE_APP_API_KEY };
export class AxiosService {
  static url = process.env.VUE_APP_BACKEND_URL;

  static async get(path: string, params?: any, cache?: Record<string, string>) {
    if (cache) {
      const cachedData = cache[JSON.stringify(path + params)];
      if (cachedData) {
        return cachedData;
      } else {
        const callResponse = await axios
          .get(this.url + path, { params, headers })
          .then((resp) => resp.data)
          .catch((e) => console.log(e));
        cache[JSON.stringify(path + params)] = callResponse;
        return callResponse;
      }
    } else {
      return axios
        .get(this.url + path, { params, headers })
        .then((resp) => resp.data)
        .catch((e) => console.log(e));
    }
  }

  static async post(path: string, body: Record<string, any>) {
    return axios
      .post(this.url + path, body, { headers })
      .then((resp) => resp.status)
      .catch((e) => console.log(e));
  }
}
