import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48d1d01f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "rankingFrame" }
const _hoisted_2 = {
  id: "name_col",
  class: "col_"
}
const _hoisted_3 = { id: "name" }
const _hoisted_4 = {
  id: "points_col",
  class: "col_"
}
const _hoisted_5 = { id: "points" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.ranking.name), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.ranking.score) + " pts", 1)
    ])
  ]))
}